<template>
    <div>
        <component :is="kycObj" :id="route.params.kycId" />
    </div>
</template>

<script>
// Get user KYC image (NRIC/Passport) via URL. E.g. https://app.signon.my/kyc/{kycId}
import { ref, inject, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import ImgKyc from '@/components/ImgKyc.vue'

export default {
    name: 'KYC',
    setup () {
        const axios = inject('axios')
        const route = useRoute()

        const kycObj = computed(() => {
            return ImgKyc
        })

        return { 
            route, ImgKyc, kycObj
        }
    }
}
</script>

<style>

</style>